

















































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import { CookieStorage } from "cookie-storage";
import configProvider from "@common/services/configProvider";

export default Vue.extend({
  data() {
    return {
      valid: false,
      loading: false,
      error: "",
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      logoUrl: configProvider.get("LOGO_URL"),
      logoText: configProvider.get("LOGO_TEXT"),
      data: {},
    };
  },
  methods: {
    async submit() {
      this.error = "";
      this.loading = true;
      try {
        const { access_token: accessToken } = await coreApiClient.callRaw("auth", "login", undefined, {
          username: this.data.loginName,
          password: this.data.password,
          deviceId: userManager.getDeviceId(),
        });
        if (accessToken) {
          userManager.setAccessToken(accessToken);
          new CookieStorage().setItem("access_token", accessToken, {
            expires: new Date(new Date().getTime() + 86400 * 1000),
          });
          location.reload();
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
});
